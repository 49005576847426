.react-datepicker-wrapper {
	width: 100%;
}

/* Styles for the datepicker calendar container */
.datepicker-calendar {
	width: 345px;
	height: fit-content;
	font-family: 'Plus Jakarta Sans', sans-serif;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(50px);
	padding-block: 10px 5px;
}

/* Styles for the month container within the datepicker */
.react-datepicker__month-container {
	width: 345px;
	height: fit-content;
	background-color: transparent;
}

/* Removes the bottom border and sets the background for the header */
.react-datepicker__header {
	border-bottom: none;
	background-color: transparent;
}

/* Styles for the previous navigation arrow */
.react-datepicker__navigation--previous {
	width: 11px;
	height: 11px;
	border: solid 1px rgb(28, 245, 161);
	border-width: 0 2px 2px 0;
	display: inline-block;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	margin: 21px 19px;
}

/* Styles for the next navigation arrow */
.react-datepicker__navigation--next {
	width: 11px;
	height: 11px;
	border: solid 1px rgb(28, 245, 161);
	border-width: 0 2px 2px 0;
	display: inline-block;
	transform: rotate(315deg);
	-webkit-transform: rotate(315deg);
	margin: 21px 19px;
}

/* Style for the current month text */
.react-datepicker__current-month {
	color: rgb(28, 245, 161);
	font-size: 16px;
	font-weight: 500;
}

/* Styles for the day names */
.react-datepicker__day-name {
	color: rgba(255, 255, 255, 0.5);
	font-size: 14px;
	text-transform: uppercase;
	margin: 15px 10px 4px 10px;
	cursor: default;
}

/* Styles for the individual days */
.react-datepicker__day {
	color: rgba(255, 255, 255, 0.5);
	font-size: 15px;
	font-weight: 400;
	border-radius: 50%;
	margin: 0px 10px 15px 10px;
}

/* Styles for the current day */
.react-datepicker__day.react-datepicker__day--today {
	color: black;
	background-color: rgba(255, 255, 255, 0.5);
}

/* Styles for days that are outside the current month */
.react-datepicker__day--outside-month {
	color: rgba(255, 255, 255, 0.2);
}

/* Styles for the day selected using the keyboard */
.react-datepicker__day--keyboard-selected {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.5);
}

/* Prevents hovering styles on days outside the current month */
.datepicker-calendar
	.react-datepicker__day.react-datepicker__day--outside-month:hover {
	background-color: transparent;
	cursor: default;
}

/* Styles for the selected day */
.react-datepicker__day--selected {
	background-color: rgb(28, 245, 161);
	border-radius: 50%;
	color: black;
}

/* Styles for the day when hovered */
.react-datepicker__day:hover {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 50%;
}

/* ----- Styles for the month range calendar: ----- */
/* Styles for the year header */
.react-datepicker-year-header {
	color: rgb(28, 245, 161);
	font-size: 16px;
	font-weight: 500;
}

/* Base styles for the month texts in the month selector */
.react-datepicker__month-container .react-datepicker__month-text {
	color: rgba(255, 255, 255, 0.5);
	font-size: 16px;
	font-weight: 400;
	border-radius: 10px;
	margin: 12px 16px;
	padding: 8px;
}

/* Styles for the month text when hovered */
.react-datepicker__month-text:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

/* Styles for the selected month */
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--selected {
	background-color: rgb(28, 245, 161) !important;
	color: black;
}

/* Styles for the month text selected using the keyboard */
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--keyboard-selected {
	background-color: transparent;
}

/* Styles for the months within the selected range */
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--in-range {
	background-color: rgb(28, 245, 161);
	color: black;
}

/* Styles for the months in the currently selecting range */
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--in-selecting-range {
	background-color: rgb(28, 245, 161);
	color: black;
}

/* Styles for the current month when it is today */
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--today {
	color: black;
	background-color: rgba(255, 255, 255, 0.5);
}

/* Override styles when the current month (today) is also within the range */
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--today.react-datepicker__month-text--in-range,
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--today.react-datepicker__month-text--in-selecting-range {
	background-color: rgb(28, 245, 161);
	color: black;
}

/* Ensure that the currently selecting range doesn't get overridden by the today class */
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--in-selecting-range:not(
		.react-datepicker__month-text--today
	),
.react-datepicker__month-container
	.react-datepicker__month-text.react-datepicker__month-text--in-range:not(
		.react-datepicker__month-text--today
	) {
	background-color: rgb(28, 245, 161);
	color: black;
}
